$deed-green-160: #225241;
$deed-green-140: #347a61;
$deed-green-120: #45a382;
$deed-green-100: #56cca2;
$deed-green-80: #78d6b5;
$deed-green-60: #9ae0c7;
$deed-green-40: #bbebda;
$deed-green-20: #ddf5ec;

$deed-green-gradient: linear-gradient(157.5deg, #56cc7e 9.21%, #56cca2 92.05%);

$mustard-yellow-100: #ffc15d;

$warning-orange-100: #ff8642;

$light-blue-100: #66a6ff;

$deed-blue-100: #19acff;

$grey-160: #222524;
$grey-140: #333735;
$grey-120: #444a47;
$grey-100: #555c59;
$grey-80: #777d7a;
$grey-60: #999d9b;
$grey-40: #bbbebd;
$grey-20: #dddede;
$grey-10: #eeefee;
$grey-5: #f7f7f7;

$red-120: #cc413f;
$red-100: #ff514f;
$red-80: #ff7472;
$red-20: #ffdcdc;
$red-10: #ffeeed;

$black-100: #000;
$black-60: #666;

$white-100: #fff;
