@import "reset";
@import "fonts";
@import "colors";

body {
  font-family: "SF Pro", "Avenir", "Open Sans", sans-serif;
  font-size: 1em;
  color: $black-100;
  background-color: $white-100;
}

a {
  color: $deed-green-100;
  &:visited {
    color: $deed-green-100;
  }
}
