@import "../../common/styles/colors.scss";

.btn {
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.btn-circle {
  border-radius: 50%;
  height: 72px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 16px;
    width: 16px;
  }
}

.btn-round {
  border-radius: 16px;
  padding: 16px 48px;
}

.btn-circular {
  border-radius: 60px;
  padding: 16px 48px;
}

.btn-grey {
  // @include btn
  background: $grey-20;
  color: $black-100;
}

.btn-gradient {
  // @include btn;
  background: $deed-green-gradient;
  color: $white-100;

  &:visited {
    color: $white-100;
  }
}
