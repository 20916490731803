@font-face {
  font-family: "SF Pro";
  src: url("../../../public/fonts/SF-Pro/SF-Pro-Display-Bold.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../../../public/fonts/SF-Pro/SF-Pro-Display-Medium.otf")
    format("otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../../../public/fonts/SF-Pro/SF-Pro-Display-Medium-Italic.otf")
    format("otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro";
  src: url("../../../public/fonts/SF-Pro/SF-Pro-Display-Regular.otf")
    format("otf");
  font-weight: 400;
  font-style: normal;
}
